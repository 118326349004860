import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router
    ) {

  afAuth.authState.subscribe((auth)=> {
    if (auth == null) { 
      // console.log('auth', auth)
      router.navigate(['login']); 
      } else {
      router.navigate(['user']);
      // console.log('auth details',auth)
      }
    });
  }

  signOut(){
    this.afAuth.auth.signOut();
  }

}
