import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

import Swal from 'sweetalert2';

declare var require: any;

var data = require('../../assets/contacts.json');

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  name$ = new Subject<string>();
  queryObservable = this.name$.pipe(
    switchMap(name => 
      this.db.list('/users', ref => ref.orderByChild(this.filters).startAt(name).endAt(name + '\uf8ff').limitToFirst(300))
      .snapshotChanges().pipe(
          map(actions => {
            return actions.map(a => {
                const data = a.payload.val();
                const uid = a.payload.key;
                return { uid, ...data };
            });
          })
      )
    )
  );

  users: any;
  updateUser: any;
  tempUsers: any;
  values: any;
  filters: any = 'name';

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private db: AngularFireDatabase
  ) { 

    // this.users = afs.collection('users', ref => ref.limit(1000) ).snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //         const data = a.payload.doc.data();
    //         const uid = a.payload.doc.id;
    //         return { uid, ...data };
    //     });
    //   })
    // );

    // this.users = this.db.list('users').snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //         const data = a.payload.val();
    //         const uid = a.payload.key;
    //         return { uid, ...data };
    //     });
    //   })
    // );

  }

  ngOnInit() {

    this.queryObservable.subscribe(queriedItems => {
      this.users = queriedItems;
    });

    // this.tempUsers = this.afs.collection('users').valueChanges();
    // this.tempUsers.subscribe((res)=>{
    //   console.log('total', res.length)
    // })
    this.updateUser = this.afs.collection('users');
    // var i = 1;
    // console.log('loggin', data[i]);
    // data.forEach(element => {
    //     console.log('from element --> ',element, i++);
    //     console.log(element[i])
    //     this.updateUser.add(element);
    // });
    // for (let i = 4801; i < 5100; i++) {
    //   this.updateUser.add(data[i])
    // }
  }

  new(){
    this.router.navigate(['add']); 
  }

  edit(data){
    // console.log(data);
    this.router.navigate(['edit', {data}]); 
  }

  delete(data){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.db.object('users/' + data).remove()
        .then( ()=> {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        })
      }
    })
  }

  onKey(event: any) { // without type info
    // this.values += event.target.value + ' | ';
    // console.log(this.values);
    this.name$.next(event.target.value.toUpperCase());
  }

  filter(event){
    console.log('from filter', event)
    this.filters = event
  }

}
