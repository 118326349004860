import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  adddb: any;

  constructor(
    private _location: Location,
    private afs: AngularFirestore,
    private db: AngularFireDatabase
  ) { }

  ngOnInit() {
  }

  addUser(ref, id, name, phone, email) {
    console.log(ref, id, name, phone, email)
    this.adddb = this.db.list('users');
    this.adddb.push({
      address: null,
      created_at: Date(),
      email: email,
      group_id : '1',
      ic: null,
      id: id,
      name: name.toUpperCase(),
      phone: phone,
      photo: null,
      ref: ref.toUpperCase()
    })
    .then( () => {
      Swal.fire(
        'Success!',
        'New data is successfully key in!',
        'success'
      )
      this._location.back();
    })
  }

  back(){
    this._location.back();
  }

}
