import { Routes, RouterModule }  from '@angular/router';

import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { AdduserComponent } from './adduser/adduser.component';
import { EdituserComponent } from './edituser/edituser.component';

// Route config let's you map routes to components
const routes: Routes = [
    { path: 'login', component: LoginComponent},
    { path: 'user', component: UserComponent},
    { path: 'add', component: AdduserComponent},
    { path: 'edit', component: EdituserComponent},
    {
        path: '',
        redirectTo: '/user',
        pathMatch: 'full'
    },
  ];
  
  export const routing = RouterModule.forRoot(routes);