import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

  userData: any;
  user: any;
  adddb: any;

  constructor(
    private _location: Location,
    private afs: AngularFirestore,
    private aRoute: ActivatedRoute,
    private db: AngularFireDatabase
  ) { }

  ngOnInit() {

    this.userData = this.aRoute.snapshot.params['data'];
    
    this.user = this.db.object('users/' + this.userData).valueChanges();

    this.user.subscribe((res)=>{
      this.user = res
    })

  } 

  editUser(ref, id, name, phone, email) {
    console.log(ref, id, name, phone, email)
    this.adddb = this.db.object('users/' + this.userData);
    this.adddb.update({
      address: null,
      created_at: Date(),
      email: email,
      group_id : '',
      ic: null,
      id: id,
      name: name.toUpperCase(),
      phone: phone,
      photo: null,
      ref: ref.toUpperCase()
    })
    .then( () => {
      Swal.fire(
        'Success!',
        'New data is successfully key in!',
        'success'
      )
      this._location.back();
    })
  }

  back(){
    this._location.back();
  }

}
